import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic7 from '../assets/images/pic07.jpg';
import loadingBoxes from '../assets/images/juggling-boxes1.jpg';
import womanSmiling from '../assets/images/woman-smiling-behind-headboard.jpg';
import coupleOnFloor from '../assets/images/a-couple-having-breakfast-on-the-floor.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Full service long distance movers</h2>
          <p>
            {' '}
            Using movers beats moving all this stuff yourself. See what we do
            for you.{' '}
          </p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Why use a long distance moving company?</h3>
          <p>
            Moving from one state to another can be difficult, there are so many
            things to plan from which <a>city to live in</a>, and which
            neighborhood to move to, to which schools your kids will go to and
            on an on. Add on top of that needing to choose interstate movers to
            assist with your move and you have quite a lot of tasks to take care
            of! The good news is that a good moving company understands what you
            are going through and can help you not only to make sure that your
            move goes smoothly but also help you plan for all the other great
            new things which you are about to do by carrying some of your burden
            and lightening your load.
          </p>

          <p>
            By speaking with movers now you can check off one more of the tasks
            and begin to feel good immediately knowing that you are one step
            closer to what ever comes next. A reputable moving company will help
            you plan well and let you know exactly what to do. Our{' '}
            <a>moving guide</a> is also a very helpful tool to make sure that
            everything goes right. Once you have scheduled movers you can use
            our moving checklist to make sure that you are on track for a
            successfull and happy move.
          </p>
          <div className="jump-to-quote1">
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
          <h3 className="major">Full service movers</h3>
          <p>
            What does a full service mover do exactly? Well we do all the stuff
            you don't really want to. Loading. Unloading. Driving those big
            dangerous trucks, remember what it was like learning to parallel
            park? Imagine parking a 26 foot truck lol. We pack. And when we say
            deliver, we don't mean like Dominoes. More like having Bobby Flay
            come to the kitchen with a full wait staff to set the table so you
            can dine in style.
          </p>

          <section className="features">
            <article>
              <a href="/LoadingAndUnloading" className="image">
                <img src={loadingBoxes} alt="man juggling moving boxes" />
              </a>
              <h3 className="major">Loading and unloading</h3>
              <p>
                Loading a truck is hard work. A lot of hard work. You have to
                pick up all that stuff, carry it down the stairs, carry it up
                the ramp, and then figure out how to stack it. Not nearly as
                much fun as tetris. So instead, let professional movers do the
                lifting while you play Nintendo.
              </p>
              <a href="/LoadingAndUnloading" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/BasicPacking" className="image">
                <img
                  src={womanSmiling}
                  alt="woman smiling behind headboard wrapped in plastic"
                />
              </a>
              <h3 className="major">Basic Packing</h3>
              <p>
                The main thing about moving is that your stuff needs to get from
                point a to point b. And it needs to get there in the same shape
                it is right now. Which means packing. Lots and lots of packing.
                That's why we include a basic packing service with your move.
              </p>
              <a href="/BasicPacking" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/FullPacking" className="image">
                <img
                  src={coupleOnFloor}
                  alt="a couple sitting together on the floor of their bedroom"
                />
              </a>
              <h3 className="major">Want movers who pack everything?</h3>
              <p>
                Maybe you read our guide on <a>how to pack</a> and realized
                packing will take a lot more time than you have. Busy with kids,
                and work? Moving your mom so she can be near her grandchildren?
                Raking in the big bucks and moving for a new position? Maybe now
                that you thought about it, you can just think of something you'd
                rather spend your time doing. Relax. We got this.
              </p>
              <a href="/FullPacking" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/CommercialMoving" className="image">
                <img src={pic7} alt="library with very tall curving bookcase" />
              </a>
              <h3 className="major">Commercial Moving</h3>
              <p>
                Is your startup moving offices? Did the board recently decide
                your business will be opening up shop in a new part of town?
                Wondering how to move an office and a dozen workers cross
                country as efficiently as possible? We have lots of experience
                moving offics, moving warehouses, and even moving libraries. Our
                loyal clients include churches, software companies, blockchain
                startups, the California Highway Patrol, and even a few federal
                judges. Whatever your needs are we are happy to work with you.
              </p>
              <a href="/CommercialMoving" className="special">
                Learn more
              </a>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
